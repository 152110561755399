import Link from 'next/link';
import React, { useMemo, useRef } from 'react';
import Slider from 'react-slick';
import { NextArrow, PrevArrow } from '@/assets/icons';
import { ICategoryHeaderTabWrapper } from '@/interfaces/category';

interface ICategorySlider {
  parent?: ICategoryHeaderTabWrapper;
  childrenCategories?: ICategoryHeaderTabWrapper[];
  isTabWrapper?: boolean;
  isBeautifulHouseTopSection?: boolean;
}

const CategorySlider = ({
  parent,
  childrenCategories,
  isTabWrapper,
  isBeautifulHouseTopSection,
}: ICategorySlider) => {
  const sliderRef = useRef<Slider>(null);
  // const [currentSlider, setCurrentSlider] = useState(0);
  const hasItemWithMoreThanTwoWords = childrenCategories?.some(item => {
    if (item && item.name) {
      return item.name.split(' ').length > 2;
    }
    return false;
  });

  const settings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      slidesToShow: isTabWrapper ? 3 : hasItemWithMoreThanTwoWords ? 1 : 2,
      arrows: false,
      slidesToScroll: 1,
      adaptiveHeight: true,
    }),
    [isTabWrapper],
  );
  const goToPrev = () => {
    sliderRef.current?.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current?.slickNext();
  };

  return (
    <div className="relative flex items-center gap-8 justify-between w-full overflow-hidden">
      <Link
        href={`/${parent?.pathFullSlug}`}
        className={`min-w-max text-[#3F4D5C] ${
          isTabWrapper
            ? 'text-[16px] font-bold uppercase'
            : 'text-[18px] font-bold uppercase'
        }  whitespace-nowrap hover:text-primary`}
      >
        {parent?.name}
      </Link>
      <div
        className="w-[40%] grow lg:flex hidden items-center justify-end"
        style={{
          animation: 'animate-moveinright 0.5s',
        }}
      >
        <div className="w-full h-[24px] hidden lg:block">
          <Slider {...settings} ref={sliderRef}>
            {childrenCategories?.map((item, index) => {
              return (
                <Link
                  key={index}
                  href={`/${item.pathFullSlug}`}
                  className={`${
                    isTabWrapper
                      ? 'text-[14px] w-full text-center font-bold uppercase whitespace-nowrap'
                      : 'text-[12px] font-medium w-fit whitespace-nowrap'
                  } text-[#3F4D5C] text-center hover:text-primary`}
                >
                  {item.name}
                </Link>
              );
            })}
          </Slider>
        </div>

        {!isBeautifulHouseTopSection &&
        childrenCategories &&
        childrenCategories?.length > 2 ? (
          <div
            className={`lg:flex hidden ${isTabWrapper ? 'gap-[6px]' : 'gap-1'}`}
          >
            <button
              onClick={goToPrev}
              aria-label="btn-prev"
              className="px-[2px]"
            >
              <PrevArrow
                className={`fill-black ${
                  isTabWrapper ? 'h-[14px] w-[8px]' : 'h-[10px] w-[5px]'
                }`}
              />
            </button>
            <button
              onClick={goToNext}
              aria-label="btn-next"
              className="px-[2px]"
            >
              <NextArrow
                className={`fill-black ${
                  isTabWrapper ? 'h-[14px] w-[8px]' : 'h-[10px] w-[5px]'
                }`}
              />
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="lg:hidden w-[60%] flex items-center justify-between gap-6 overflow-x-scroll no-scrollbar">
        {childrenCategories?.map((item, index) => {
          return (
            <Link
              key={index}
              href={`/${item.pathFullSlug}`}
              className={`${
                isTabWrapper
                  ? 'text-[14px] text-center font-bold uppercase min-w-fit'
                  : 'text-[12px] font-medium w-fit whitespace-nowrap'
              } text-[#3F4D5C] flex text-center justify-center`}
            >
              {item.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default CategorySlider;
